import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'
import React from 'react'; 
import  { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLocation,Outlet, Link } from "react-router-dom";
import { Container, Row, Col } from 'react-grid';
import '../App.css'
const Model = () => {
 
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (

    
    <>
    <link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css"
  integrity="sha384-9ndCyUaIbzAi2FUVXJi0CjmCapSmO7SnpJef0486qhLnuZ2cdeRhO02iuK6FUUVM"
  crossorigin="anonymous"
/>
    <Button variant="primary" onClick={handleShow}>
      Launch demo modal
    </Button>
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
        <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body  >Woohoo, you're reading this text in a modal!Woohoo, you're reading this text in a modal!Woohoo, you're reading this text in a modal!Woohoo, you're reading this text in a modal!Woohoo, you're reading this text in a modal!Woohoo, you're reading this text in a modal!Woohoo, you're reading this text in a modal!</Modal.Body>
        <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
        Close
        </Button>
        <Button variant="primary" onClick={handleClose}>
        Save Changes
        </Button>
        </Modal.Footer>
        </Modal>
  </>
  )
};

export default Model;