import React from 'react';
import '../App.css'
import axios from 'axios';
import { Container, Row, Col } from 'react-grid';
import ChildComponent from "./Model";
import Modal from 'react-bootstrap/Modal'
import { Button } from 'react-bootstrap'
import { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { Outlet, Link } from "react-router-dom";
import '../App.css'
export default class ImageList extends React.Component {
  
  //Get Files
  state = {
    persons: [],
    showModal: false,

  }
  openModal = () => {
    this.setState({ showModal: true });
  }
  closeModal = () => {
    this.setState({ showModal: false });
  }
  componentDidMount() {
    axios.get(`https://newapi.ysbstech.com/api/type/getfiles`)
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
      })
  }


  render() {


   const  fngetfiles= () =>   {

      axios.get(`https://newapi.ysbstech.com/api/type/getfiles`)
      .then(res => {
        const persons = res.data;
        this.setState({ persons });
      })
  
    }
    async function handleClick(fileName) {

      //delete file   ------------------------------ Begin
       if (window.confirm("Are you sure you want delete image?")) {
        try {
          let res = await axios.post('https://newapi.ysbstech.com/api/type/deletefiles', { image: fileName });
        } catch (error) {
          //document.getElementById("imgstatus").innerHTML = "<span style='color:red'><b>Image did not delete </b></span>";
          console.error('Error Deleting Image From Server:', error);
        }
      }
        
      //delete file   ------------------------------ Begin
    //  window.location.reload(false);
    fngetfiles();
      
    };

   
     //GetFiles Again ------------------------------
    async function imageClick(fileName) {
      alert("coming soon");
    };

    return (
      <div>
         
        <Row>
          {
            this.state.persons
              .map(person =>
                <Col className='CustCol CustPhotoFrame' xs={2} >
                  <img className="CustImage" src={person.fileName} style={{ width: "190px", maxHeight: "100px" }}
                    onClick={() => imageClick(person.file)}
                  />
                  <div>
                    <div>
                      <div onClick={() => handleClick(person.file)} className='CustDelete'>
                        DELETE
                      </div>
                    </div>
                  </div>
                </Col>
              )
          }
        </Row>
        <Row>
          <Col>
            <button className='CustHideALl'  >
              Delete selected images
            </button>
          </Col>
        </Row>
      </div>
    )
  }
}
