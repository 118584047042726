import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { Container, Row, Col } from 'react-grid';
import { Routes, Route, useNavigate } from 'react-router-dom'
import ImageList from './ImageList.js'
const ViewImages = () => {
  return (
    <Container style={{ marginTop: '50px' }}>
      <Row>
        <Col>
          <ImageList />
        </Col>
      </Row>
    </Container>
  );
};
export default ViewImages;


