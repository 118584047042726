import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";

import ViewImages from "./pages/ViewImages";
import Model from "./pages/Model";
import NoPage from "./pages/NoPage";
<link
      href="https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap"
      rel="stylesheet"
    />

export default function App() {
  return (
    <BrowserRouter >
      <Routes >
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
         
          <Route path="ViewImages" element={<ViewImages />} />
          <Route path="Model" element={<Model />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);