import { Outlet, Link } from "react-router-dom";
import { Container, Row, Col } from 'react-grid';
import '../App.css'
const Layout = () => {
  return (
    <>
      <nav>
        <Row>
          <Col className='CustTxt'> 
          <div  > <Link className='CustHref' to="/">Home</Link>  </div>
          <div  > <Link className='CustHref' to="/">|</Link>  </div>
          <div><Link className='CustHref' to="/ViewImages"> View All Captured Images</Link></div>
          </Col>
        </Row>
      </nav>
      <Outlet />
    </>
  )
};

export default Layout;