import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import axios from 'axios';
import { Container, Row, Col } from 'react-grid';
import { Routes, Route, useNavigate } from 'react-router-dom'
import ViewImages from "./ViewImages";
const WebcamCapture = () => {
  const webcamRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");

  
  const capture = async () => {
    document.getElementById("divcapturedImage").style.display='';
    var now = new Date();
    var datetime = now.toLocaleString();

    // Insert date and time into HTML

    document.getElementById("datetime").innerHTML = "<span style='color:blue'><b>Last  captured : </b></span>" + getDate();

    const imageSrc = webcamRef.current.getScreenshot();

    try {

      let res = await axios.post('https://newapi.ysbstech.com/api/type/upload', { image: imageSrc });
      if (res.status == 200)
        document.getElementById("imgstatus").innerHTML = "<span style='color:green;text-align:center'><b>Image successfully saved </b></span>";
      else
        document.getElementById("imgstatus").innerHTML = "<span style='color:red'><b>Image did not saved </b></span>";


      //console.log(res.status) ;
      //console.log('Image sent to server.');;
      setImgSrc(imageSrc);

    } catch (error) {
      setImgSrc(imageSrc);
      document.getElementById("imgstatus").innerHTML = "<span style='color:red'><b>Image did not saved </b></span>";

      console.error('Error sending image to server:', error);
    }
  };
  const navigate = useNavigate();
  const navigateToContacts = () => {
    // 👇️ navigate to /contacts
    navigate('/ViewImages');
  };
  return (


    <Container style={{ marginTop: '10px' }}>
      <Row  >
        <Col className="CustBg1">
          <h1 style={{ marginTop: '0px', FontFace: 'Arial', color: '#541268' }}>Live Webcam    </h1>
          <div style={{ margin: 'auto' }}>
            <Webcam audio={false} ref={webcamRef} screenshotFormat="image/jpeg" style={{

              borderRadius: "10px",

            }} />
          </div>
          <div style={{ margin: 'auto', align: 'center' }}>
            <button
              className='CustCapture'
              onClick={capture}
            >
              Capture Image
            </button>
          </div></Col>

        <Col className="CustBg1" id='divcapturedImage' style={{ display: 'none'}}>
          <h1 style={{ marginTop: '0px', FontFace: 'Arial', color: '#541268' }}>Captured Image  </h1>

          {
            imgSrc !== "" &&
            <img src={imgSrc} alt="Captured" style={{ marginTop: '10px', width: '100%', borderRadius: "10px" }} />
          }
          <div id='datetime'  ></div>
          <div id='imgstatus' ></div>
          <button onClick={navigateToContacts} style={{ marginTop: '140px' }}

            className='CustCapture'

          >View All Images</button>


          <Routes>
            <Route path="/ViewImages" element={<ViewImages />} />
          </Routes>
        </Col>
      </Row>

    </Container>



  );

};

function Contacts() {
  return <h2>Contacts</h2>;
}
function getDate() {

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  const hours = today.getHours();
  const minits = today.getMinutes();
  const seconds = today.getSeconds();
  const monthName = monthNames[today.getMonth()];
  const dayName = getDayName()
  return `${dayName},${monthName},${month}-${date}-${year} ${hours}:${minits}:${seconds}`;
}
function getDayName(date = new Date(), locale = 'en-US') {
  return date.toLocaleDateString(locale, { weekday: 'short' });
}
export default WebcamCapture;
